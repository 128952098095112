import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDrlgg8Me7Akb3ui8MrwFi_pFA2__tf7Wk',
  authDomain: 'codelab-academy-9a860.firebaseapp.com',
  projectId: 'codelab-academy-9a860',
  storageBucket: 'codelab-academy-9a860.appspot.com',
  messagingSenderId: '345351513069',
  appId: '1:345351513069:web:d96c42e6a80141caf5fde4',
  measurementId: 'G-P0PMHTW4D8'
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)