import React, { useState } from 'react'
import constants from '../../global/index'
import toast from 'react-hot-toast'
import { db } from '../../firebase-config'
import { addDoc, collection } from 'firebase/firestore'

const Enquiry = () => {
  const enquiriesCollectionRef = collection(db, 'enquiries')

  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ contact, setContact ] = useState('')
  const [ course, setCourse ] = useState('')
  const [ reference, setReference ] = useState('')

  const createEnquiry = async () => {
    try {
      if (course === 'NONE') {
        toast.error('Please select course!')
        return
      }

      if (!name || !email || !contact) {
        toast.error('Invalid fields!')
        return
      }

      await addDoc(enquiriesCollectionRef, {
        name,
        email,
        contact,
        course,
        reference
      })

      toast.success('We get your enquiry!')
    } catch (e) {
      console.log('createEnquiry => eMessage :: ', e.message)
      toast.error('Error while saving enquiry !')
    }
  }

  return (
      <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="container max-w-md mx-auto bg-white dark:bg-gray-800  rounded-lg shadow-sm">
          <div className="text-center p-5 bg-sky-950 rounded-t-lg">
            <h1 className="text-xl text-white font-bold uppercase">Enquiry form</h1>
          </div>
          <div className="m-7">
            <div className="mb-6">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Full Name</label>
              <input type="text" name="name" id="name" placeholder="Full Name" onChange={(e) => setName(e.target.value)}
                     className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email</label>
              <input type="email" name="email" id="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}
                     className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Contact Number</label>
              <input type="text" name="number" id="number" placeholder="Contact Number" onChange={(e) => setContact(e.target.value)}
                     className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
            </div>
            <div className="mb-6">
              <label htmlFor="Course Name" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Select an option</label>
              <select id="Course Name" onChange={(e) => {
                console.log('e => e :: ', e.target.value)
                setCourse(e.target.value)
              }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue="NONE">
                <option value="NONE">Selected a course</option>
                <option value={constants.COURSE_TYPE.WEB_DESIGN}>Web Design</option>
                <option value={constants.COURSE_TYPE.WEB_DEVELOPMENT}>Web Development</option>
                <option value={constants.COURSE_TYPE.FULL_STACK_DEVELOPMENT}>Full Stack Development</option>
                <option value={constants.COURSE_TYPE.REACT_NATIVE_DEVELOPMENT}>React Native Development</option>
                <option value={constants.COURSE_TYPE.FLUTTER_DEVELOPMENT}>Flutter Development</option>
                <option value={constants.COURSE_TYPE.ANDROID_DEVELOPMENT}>Android Development</option>
                <option value={constants.COURSE_TYPE.PYTHON_DEVELOPMENT}>Python Development</option>
                <option value={constants.COURSE_TYPE.UI_UX_DESIGN}>UI-UX Design</option>
                <option value={constants.COURSE_TYPE.DEVOPS_COURSE}>devops Course</option>
                <option value={constants.COURSE_TYPE.FREELANCING_COURSE}>Freelancing Course</option>
              </select>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Reference</label>
              <input type="text" name="name" id="name" placeholder="Reference Name" onChange={(e) => setReference(e.target.value)}
                     className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
            </div>
            <div className="mb-6">
              <button type="submit" className="font-bold w-full px-3 py-4 text-lg text-white bg-sky-950 rounded-md focus:bg-sky-950 focus:outline-none" onClick={() => {
                createEnquiry()
              }}>Submit Form
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Enquiry
