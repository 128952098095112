exports.COURSE_TYPE = {
  WEB_DESIGN: 'Web Design',
  WEB_DEVELOPMENT: 'Web Development',
  FULL_STACK_DEVELOPMENT: 'Full Stack Development',
  REACT_NATIVE_DEVELOPMENT: 'React Native Development',
  FLUTTER_DEVELOPMENT: 'Flutter Development',
  ANDROID_DEVELOPMENT: 'Android Development',
  PYTHON_DEVELOPMENT: 'Python Development',
  UI_UX_DESIGN: 'UI-UX Design',
  DEVOPS_COURSE: 'Devops Course',
  FREELANCING_COURSE: 'Freelancing Course'
}