import React from 'react'
import { Toaster } from 'react-hot-toast'
import Enquiry from './Component/enquiry'

function App() {
  return (
      <div>
        <Enquiry/>
        <Toaster/>
      </div>
  )
}

export default App
